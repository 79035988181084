<template>
  <section>
    <ServicesTopBar />
    <MemberReviews />
    <SiteFooter />
  </section>
</template>

<script>
import ServicesTopBar from '../components/services/ServicesTopBar.vue'
import MemberReviews from '../components/services/MemberReviews.vue'
import SiteFooter from '../components/site/SiteFooter.vue';

export default {
  components: { ServicesTopBar, MemberReviews, SiteFooter },
}
</script>

<style scoped>

</style>

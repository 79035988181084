<template>
  <div class="service_list_box">
    <div class="service_item_list">
      <div class="row">
        <div
          class="col-md-12 mb-3"
        >
          <div class="sorting_view_main_box">
            <div class="title_view">
              <h4 class="search_result_text">
                Reviews({{ totalRows }})
              </h4>
              <!-- v-b-tooltip.hover.top="'sort alphabetically'" -->
              <div

                class="sort_item_box_view"
                @click="sortData"
              >
                <img
                  v-if="sort_type === 'DESC'"
                  :src="SortDown"
                  alt="sort"
                >
                <img
                  v-if="sort_type === 'ASC'"
                  v-b-tooltip.hover.top="'sort alphabetically'"
                  :src="SortUp"
                  alt="sort"
                >
              </div>
            </div>

            <div class="search-input-view">
              <b-form-input
                id="h-search-keyword"
                v-model="searchTerm"
                type="text"
                placeholder="search review."
                class="form-control"
                style="min-width: 450px;"
              />
            </div>
          </div>
        </div>
        <div class="row service_list_box">
          <div
          v-for="(review, index) in paginatedData"
          :key="index"
          class="col-md-6"
          @click="showDetails(review)"
        >
          <div class="review__card">
            <div class="review_status_view mb-2">
              <div class="review_profile_view">
                <b-avatar
                  :src="getImage(review.business_profile.cover)"
                  size="100"
                />
                <div class="profile_details">
                  <h5>{{ review.business_profile && review.business_profile.name }}</span></h5>
                  <div class="raiting_stars">
                    <b-form-rating
                      v-model="review.rating"
                      variant="warning"
                      inline
                      no-border
                      readonly
                      class="raiting_box"
                    />
                  </div>
                </div>
              </div>

              <!-- <b-badge
                :variant="review.status === 'active' ? 'light-success':
                  review.status === 'reported' ? 'light-danger' : review.status === 'under review' ? 'light-warning' : 'light-secondary'
                "
              >
                {{ review.status === 'reported' ? 'Review reported' : 'Review ' + review.status }}
              </b-badge> -->
            </div>
            <b-badge
              v-if="review.status === 'reported'"
              :variant="review.status === 'active' ? 'light-success':
                review.status === 'reported' ? 'light-danger' : review.status === 'under review' ? 'light-warning' : 'light-secondary'
              "
            >
              {{ review.status === 'reported' ? 'Review reported' : 'Review ' + review.status }}
            </b-badge>
            <p class="review_display_text mt-1">
              {{ review.review | setTruncate(350, "...") }}
            </p>
            <div
              class="extra_review_content"
            >
              <div class="row mb-1">
                <div class="col-md-4 review-content">
                  <feather-icon
                    icon="ChevronsRightIcon"
                    class="mr-1"
                  />
                  <span class="review-content-heading">Customer Service</span>
                </div>
                <div class="col-md-8">
                  {{ review.content.customer_service }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-4 review-content">
                  <feather-icon
                    icon="ChevronsRightIcon"
                    class="mr-1"
                  />
                  <span class="review-content-heading">Pricing</span>
                </div>
                <div class="col-md-8">
                  {{ review.content.pricing }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-4 review-content">
                  <feather-icon
                    icon="ChevronsRightIcon"
                    class="mr-1"
                  />
                  <span class="review-content-heading">Timeliness</span>
                </div>
                <div class="col-md-8">
                  {{ review.content.timeliness }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-4 review-content">
                  <feather-icon
                    icon="ChevronsRightIcon"
                    class="mr-1"
                  />
                  <span class="review-content-heading">Quality</span>
                </div>
                <div class="col-md-8">
                  {{ review.content.quality }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-4 review-content">
                  <feather-icon
                    icon="ChevronsRightIcon"
                    class="mr-1"
                  />
                  <span class="review-content-heading">Recommendation</span>
                </div>
                <div class="col-md-8">
                  {{ review.content.recommend }}
                </div>

                <!-- <div class="col-md-12">
                  <b-button
                    v-if="userData.user_info.user_type !== 'staff' && userData.user_info.user_type !== 'manager' && review.reviewer.user.id !== userData.user_info.id"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-success"
                    class="mt-2"
                    @click="showReportReview"
                  >
                    Report Review
                  </b-button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        </div>
        
      </div>
      <div
        v-if="paginatedData.length >= 4 || currentPage > 1"
        class="pagination_box"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="lg"
          class="my-0"
          @change="onPageChanged"
        />
      </div>
      <b-modal
        id="app-modal"
        :title="is_update ? 'Update' : 'Add New'"
        hide-footer
        @close="hide"
      >
        <b-card-text>
          <div
            class="review_content"
            :class="is_customer_service === false ? 'is_review_content_hidden' : ''"
          >
            <h4 class="mb-1">
              Customer Service
            </h4>
            <p class="mb-2">
              How would you rate the responsiveness of our customer service team?
            </p>
            <b-form-group label="">
              <b-form-radio-group
                v-model="customer_service"
                :options="customer_services"
                name="customer_service"
                class="app_radio_box"
                stacked
                @change="checkCustomerService"
              />
            </b-form-group>
          </div>

          <div
            class="review_content"
            :class="is_quality_service === false ? 'is_review_content_hidden' : ''"
          >
            <h4 class="mb-1">
              Quality of Service
            </h4>
            <p class="mb-2">
              How satisfied were you with the overall quality of service you received?
            </p>
            <b-form-group label="">
              <b-form-radio-group
                v-model="quality"
                :options="quality_services"
                name="quality_service"
                class="app_radio_box"
                stacked
                @change="checkQualityService"
              />
            </b-form-group>
          </div>

          <div
            class="review_content"
            :class="is_money_value === false ? 'is_review_content_hidden' : ''"
          >
            <h4 class="mb-1">
              Value for Money
            </h4>
            <p class="mb-2">
              Were you satisfied with the level of customer service provided for the price paid?
            </p>
            <b-form-group label="">
              <b-form-radio-group
                v-model="pricing"
                :options="money_values"
                name="money_value"
                class="app_radio_box"
                stacked
                @change="checkMoneyValue"
              />
            </b-form-group>
          </div>

          <div
            class="review_content"
            :class="is_timeless === false ? 'is_review_content_hidden' : ''"
          >
            <h4 class="mb-1">
              Timeliness
            </h4>
            <p class="mb-2">
              Was the service delivered on time?
            </p>
            <b-form-group label="">
              <b-form-radio-group
                v-model="timeliness"
                :options="timeless"
                name="timeless"
                class="app_radio_box"
                stacked
                @change="checkTimeliness"
              />
            </b-form-group>
          </div>
          <div
            class="review_content"
            :class="!is_recommend ? 'is_review_content_hidden' : ''"
          >
            <h4 class="mb-1">
              Recommend
            </h4>
            <p class="mb-2">
              Would you recommend this service to others based on the quality of service you received?
            </p>
            <b-form-group label="">
              <b-form-radio-group
                v-model="recommend"
                :options="recommends"
                name="recommend"
                class="app_radio_box"
                stacked
                @change="checkRecommend"
              />
            </b-form-group>
          </div>

          <div
            v-if="is_rating_enable"
            class="review_content"
          >
            <h4 class="mb-1 text-center">
              Ratings
            </h4>
            <p class="text-center">
              How will you rate this service?
            </p>

            <div class="text-center">
              <feather-icon
                icon="FrownIcon"
                size="20"
                class="rating_emoji text-danger"
                @click="setRating"
              />
              <b-form-rating
                id="rating-lg"
                v-model="value"
                size="lg"
                class="raiting_box"
                inline
                variant="warning"
                @change="checkRating"
              />
            </div>
          </div>

          <div
            v-if="is_write_comment"
            class=""
          >
            <validation-observer ref="reviewForm">
              <validation-provider
                #default="{ errors }"
                name="comment"
                rules="required"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="review_message"
                  placeholder="Write some comment..."
                  :state="errors.length > 0 ? false : null"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <br>
              </validation-provider>
            </validation-observer>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              class="mt-2"
              @click="updateReview"
            >
              Save Changes
            </b-button>
          </div>

          <div
            class="edit-next-button"
          >
            <b-button
              v-if="edit_page > 0"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              class="mt-2"
              @click="prevPage"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>
            <b-button
              v-if="edit_page < 6"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              class="mt-2"
              @click="nextPage"
            >
              <feather-icon icon="ArrowRightIcon" />
            </b-button>
          </div>
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BFormRating, BFormCheckbox, BAvatar, BPagination, BFormInput,
  BRow, BCol, BFormGroup, BFormRadioGroup, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import FrownIcon from '../../../assets/images/icons/fown.png'
import SortDown from '../../../assets/images/icons/sortdown.png'
import SortUp from '../../../assets/images/icons/sortup.png'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BFormRating,
    BTable,
    BFormCheckbox,
    BAvatar,
    BPagination,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BBadge,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      SortUp,
      SortDown,
      is_update: false,
      perPage: 4,
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
      pageOptions: [3, 5, 10],
      currentPage: 1,
      FrownIcon,
      selected: '',
      edit_page: 0,
      is_edit_mode: false,
      required,
      value: 0,
      business_profile_id: null,
      member_id: null,
      review_message: '',
      is_customer_service: true,
      is_quality_service: false,
      is_money_value: false,
      is_timeless: false,
      is_recommend: false,
      is_rating_enable: false,
      is_write_comment: false,
      searchTerm: '',
      sort_type: 'DESC',
      customer_services: [
        { text: 'Excellent', value: 'Excellent' },
        { text: 'Good', value: 'Good' },
        { text: 'Fair', value: 'Fair' },
        { text: 'Poor', value: 'Poor' },
        { text: 'Very poor', value: 'Very poor' },
      ],
      quality_services: [
        { text: 'Very satisfied', value: 'Very satisfied' },
        { text: 'Satisfied', value: 'Satisfied' },
        { text: 'Neutral', value: 'Neutral' },
        { text: 'Dissatisfied', value: 'Dissatisfied' },
        { text: 'Very dissatisfied', value: 'Very dissatisfied' },
      ],
      money_values: [
        { text: 'Yes, I was satisfied', value: 'Yes, I was satisfied' },
        { text: 'Somewhat satisfied', value: 'Somewhat satisfied' },
        { text: 'Neutral', value: 'Neutral' },
        { text: 'Somewhat dissatisfied', value: 'Somewhat dissatisfied' },
        { text: 'No, I was not satisfied:', value: 'No, I was not satisfied' },
      ],
      timeless: [
        { text: 'Yes, the service was delivered on time', value: 'Yes, the service was delivered on time' },
        { text: 'Somewhat on time', value: 'Somewhat on time' },
        { text: 'Neutral', value: 'Neutral' },
        { text: 'Somewhat late', value: 'Somewhat late' },
        { text: 'No, the service was not delivered on time', value: 'No, the service was not delivered on time' },
      ],
      recommends: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' },
      ],
      customer_service: '',
      quality: '',
      pricing: '',
      recommend: '',
      timeliness: '',
      review_id: null,
      is_reivew_content_show: [],
      user_type: '',
    }
  },
  computed: {
    reviews() {
      const items = this.$store.state.review.reviews
      return items.filter(item => item.business_profile.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
    },
    paginatedData: {
      get() {
        const start = (this.currentPage - 1) * this.perPage
        const end = start + this.perPage
        return this.reviews.slice(start, end)
      },
      set(value) {
        return value
      },
    },
    totalRows() {
      return this.reviews.length
    },
    reviewItems() {
      return this.reviews.map(item => ({
        ...item,
        business_name: `${item.business_profile.name}`,
      }))
    },
    userData() {
      return this.$store.state.user.profile
    },
  },
  mounted() {
    this.getReviews()
    this.user_type = localStorage.getItem('gts_user_type')
  },
  methods: {
    getReviews() {
      this.$store.dispatch('review/getMemberReviews', { member_id: this.userData.id, per_page: 20, page: 1 })
    },
    onPageChanged(page) {
      this.currentPage = page
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      this.paginatedData = this.reviews.slice(start, end)
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return image
    },
    sortData() {
      if (this.sort_type === 'DESC') {
        this.sort_type = 'ASC'
      } else if (this.sort_type === 'ASC') {
        this.sort_type = 'DESC'
      }
      this.$store.dispatch('review/sortReview', { data: this.reviews, sort_type: this.sort_type })
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
      this.emptyData()
    },
    emptyData() {
      this.value = 0
      this.review_message = ''
      this.is_customer_service = true
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_rating_enable = false
      this.is_write_comment = false
      this.edit_page = 0
      this.customer_service = ''
      this.quality = ''
      this.money_value = ''
      this.timeliness = ''
    },
    showDetails(review) {
      console.log(review)
      this.business_profile_id = review.business_profile_id
      this.member_id = review.member_id
      this.edit_page = 0
      this.is_update = true
      this.review_id = review.id
      this.customer_service = review.content.customer_service
      this.quality = review.content.quality
      this.pricing = review.content.pricing
      this.recommend = review.content.recommend
      this.timeliness = review.content.timeliness
      this.review_message = review.review
      this.review_message = review.review
      this.value = review.rating
      this.showModal()
    },
    toggleReview(status, data) {
      const item = data
      item.status = status
      this.$store.dispatch('review/updateReview', item)
    },
    nextPage() {
      this.edit_page += 1
      if (this.edit_page === 1) {
        this.is_customer_service = false
        this.is_quality_service = true
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 2) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = true
        this.is_timeless = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 3) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = true
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 4) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = true
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 5) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = true
        this.is_write_comment = false
      }

      if (this.edit_page === 6) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = true
      }
    },
    prevPage() {
      this.edit_page -= 1
      if (this.edit_page === 0) {
        this.edit_page = 0
        this.is_customer_service = true
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }
      if (this.edit_page === 1) {
        this.is_customer_service = false
        this.is_quality_service = true
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 2) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = true
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 3) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = true
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 4) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = true
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 5) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = true
        this.is_write_comment = false
      }

      if (this.edit_page === 6) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = true
      }
    },
    showReviewContent(id) {
      if (this.is_reivew_content_show.includes(id)) {
        this.is_reivew_content_show = this.is_reivew_content_show.filter(item => item !== id)
      } else {
        this.is_reivew_content_show.push(id)
      }
    },
    updateReview() {
      this.$refs.reviewForm.validate().then(success => {
        if (success) {
          const data = {
            id: this.review_id,
            business_profile_id: this.business_profile_id,
            member_id: this.userData.id,
            rating: this.value,
            review: this.review_message,
            content: {
              customer_service: this.customer_service,
              quality: this.quality,
              pricing: this.pricing,
              recommend: this.recommend,
              timeliness: this.timeliness,
            },

          }
          this.$store.dispatch('businessProfile/updateMemberReview', data)
          this.hide()
          this.edit_page = 0
          this.is_edit_mode = false
          const dom = this
          setTimeout(() => {
            dom.getReviews()
          }, 100)
        }
      })
    },

    checkCustomerService(item) {
      this.is_customer_service = false
      this.is_quality_service = true
      this.is_money_value = false
      this.is_timeless = false
      this.customer_service = item
      this.edit_page = 0
    },
    checkQualityService(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = true
      this.is_timeless = false
      this.quality = item
      this.edit_page = 1
    },
    checkMoneyValue(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = true
      this.pricing = item
      this.edit_page = 2
    },
    checkTimeliness(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_recommend = true
      this.timeliness = item
      this.edit_page = 3
    },
    checkRecommend(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_recommend = false
      this.is_rating_enable = true
      this.recommend = item
      this.edit_page = 4
    },
    checkRating() {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_recommend = false
      this.is_rating_enable = false
      this.is_write_comment = true
      this.edit_page = 5
    },
    setRating() {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_rating_enable = false
      this.is_recommend = false
      this.value = 0
      this.is_write_comment = true
      this.edit_page = 5
    },
  },
}
</script>

<style scoped>

</style>
